import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

const dataset = document.getElementById('app').dataset
const params = [...new URL(document.location).searchParams.entries()].filter(item => item[0] !== 'lang')
let customData = null

if (params.length > 0) {
  customData = JSON.stringify(params.reduce((acc, item) => {
    acc[item[0]] = item[1]
    return acc
  }, {}))
}

new Vue({
  render: h => h(App, {
    props: {
      restrictions: JSON.parse(dataset.restrictions),
      lang: document.getElementsByTagName('html')[0].getAttribute('lang'),
      target: dataset.target,
      autoUpload: dataset.autoUpload === 'true',
      customData: customData ?? null
    }
  })
}).$mount('#app')
