<template>
    <div id="app">
        <div id="uppy"></div>
    </div>
</template>
                                  
<script>
import 'es6-promise/auto'
import 'whatwg-fetch'
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import Webcam from '@uppy/webcam'
import XHRUpload from '@uppy/xhr-upload'
import Czech from '@uppy/locales/lib/cs_CZ'
import English from '@uppy/locales/lib/en_US'
import Croatia from '@uppy/locales/lib/hr_HR'
import Slovak from '@uppy/locales/lib/sk_SK'

let uploader = null

export default {
    name: 'App',
    props: {
        restrictions: {
            type: Object,
            required: false,
            default: () => {}
        },
        lang: {
            type: String,
            required: false,
            default: 'en'
        },
        target: {
            type: String,
            required: true
        },
        autoUpload: {
            type: Boolean,
            required: false,
            default: true
        },
        customData: {
            type: String,
            required: false,
            default: null
        }
    },
    mounted () {
        let locale = English
        switch (this.lang) {
            case 'cz':
            case 'cs':
                locale = Czech
                break
            case 'sk':
                locale = Slovak
                break
            case 'hr':
                locale = Croatia
                break
        }
        uploader = new Uppy({
            ...{
                autoProceed: this.autoUpload
            },
            restrictions: ((this.restrictions) ? this.restrictions : {}),
            locale 
        })
        if (this.customData) {
            uploader.setMeta({
                fileCustomData: this.customData
            })
        }
        uploader
            .use(Webcam, {
                onBeforeSnapshot: () => Promise.resolve(),
                countdown: false,
                modes: ['picture'],
                mirror: false,
                facingMode: 'environment'
            })
            .use(Dashboard, {
                target: '#uppy',
                inline: true,
                plugins: ['Webcam'],
                width: '100%',
                height: '100%'
            })
            .use(XHRUpload, {
                endpoint: `${this.target}`
            })
    }
}
</script>

<style lang="scss">
    @import '../node_modules/bootstrap/dist/css/bootstrap-reboot';
    @import '../node_modules/@uppy/core/dist/style';
    @import '../node_modules/@uppy/webcam/dist/style';
    @import '../node_modules/@uppy/dashboard/dist/style';

    body {
        position: relative;
    }

    #app {
        display: block;
        width: 100%;
        height: 100vh;
        position: relative;
    }

    .uppy-Dashboard-inner {
        width: 100% !important;
    }

    #uppy {
        display: flex;
        width: 100%;
        height: 100%;
    }

    .uppy-Root {
        width: 100%;
        height: 100vh;
    }

    .uppy-Dashboard {
        height: 100vh;
    }

    .uppy-Webcam-footer {
        min-height: 11rem;
        transform: translateY(-5rem);
    }
</style>
